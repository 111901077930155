import { createStore as reduxCreateStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { isBrowser } from "../utils/auth";
import reducer from "./reducer";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const createStore = (preloadedState) =>
  reduxCreateStore(
    persistedReducer,
    preloadedState,
  );

export default createStore;
