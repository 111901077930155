import React from "react"
import { Provider } from "react-redux"
import initialState from "./src/state/initial_state" 
import { persistStore } from "redux-persist"
import createStore from "./src/state/createStore"
import { PersistGate } from 'redux-persist/integration/react'

export default ({ children }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore(initialState)
  const persistor = persistStore(store);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
