/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import Amplify, {Auth} from "aws-amplify";

import { setUser } from "./src/utils/auth";
import awsconfig from "./src/aws-exports";
import WrapWithProvider from "./wrap-with-provider"

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
    })
    .catch((err) => {
      window.localStorage.setItem("gatsbyUser", null);
    });
};

export const wrapRootElement = ({ element }) => {
  Amplify.configure(awsconfig);
  return <WrapWithProvider>{element}</WrapWithProvider>;
};
