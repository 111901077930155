import { createMuiTheme } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

import OpenSansTtf from "../fonts/OpenSans-Light.ttf";
import LeagueGothic from "../fonts/LeagueGothic-Regular.otf";

const leagueGothic = {
  fontFamily: "LeagueGothic",
  fontStyle: "normal",
  fontDisplay: "block",
  fontWeight: "normal",
  src: `
    local('LeagueGothic'),
    url(${LeagueGothic}) format('opentype')
  `,
};

const openSansLight = {
  fontFamily: "OpenSans",
  fontStyle: "light",
  fontDisplay: "block",
  fontWeight: "light",
  src: `
    local('OpenSans'),
    local('OpenSans-Light'),
    url(${OpenSansTtf}) format('truetype')
  `,
};

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#01000f",
      paper: grey[800],
    },
    primary: {
      main: "#fafafa",
    },
    secondary: {
      main: "#d4341c",
      dark: "#922714",
    },
    yellow: {
      main: "#efe788",
    },
    red: {
      main: "#d3371b",
    },
    orange: {
      main: "#f89947",
    },
    gray: {
      main: "#cfcdc3",
    },
    link: {
      main: "#0000EE",
    },
    iceBlue: {
      main: "#5271ff",
    },
    green: {
      main: "#3ad31b",
    },
  },
  typography: {
    fontFamily: ["OpenSans", "LeagueGothic", "sans-serif"],
    h1: {
      fontFamily: ["OpenSans", "Arial", "sans-serif"],
      fontSize: "2rem",
    },
    h2: {
      fontFamily: ["OpenSans", "Arial", "sans-serif"],
      fontSize: "1.8rem",
    },
    h3: {
      fontFamily: ["LeagueGothic", "Arial", "sans-serif"],
      fontSize: "1.5rem",
    },
    subtitle1: {
      fontFamily: ["OpenSans", "Arial", "sans-serif"],
      fontSize: "0.75rem",
    },
  },
  presets: {
    width: "17rem",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [leagueGothic, openSansLight],
      },
    },
  },
});

export default theme;
