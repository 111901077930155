module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-google-gtag-virtual-7207736ba3/0/cache/gatsby-plugin-google-gtag-npm-2.8.0-ed5dcc496d-e733938237.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FXRLLN9MGY"],"gtagConfig":{"anonymize":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-theme-material-ui-top-layout-virtual-f7a48224a0/0/cache/gatsby-theme-material-ui-top-layout-npm-1.0.3-b086438656-2ae7773157.zip/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-material-ui-virtual-3ba8d23193/0/cache/gatsby-plugin-material-ui-npm-2.1.10-b8f9cb8bdc-32a12c93b5.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-f2a99c08ea/0/cache/gatsby-plugin-manifest-npm-2.12.1-284866089d-6560a4dd73.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Homeowner Dashboard by HomeClix","short_name":"ZipClix","start_url":"/","background_color":"#9a0b1a","theme_color":"#9a0b1a","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d115da0a3d3a449b33382d37c04d9ac"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
