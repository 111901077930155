/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2d4tysladjd33gck2oyezjwvlu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hfej5yd4pzeujmpxx2qnkhrtkm",
    "aws_cognito_identity_pool_id": "us-east-1:a8bb0722-7725-44ce-bc4d-009ceb3f443f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IMzDgesMJ",
    "aws_user_pools_web_client_id": "5hum9l05tj7qk5f1pdahgp790r",
    "oauth": {},
    "aws_user_files_s3_bucket": "zipcliximages114618-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
