import {
  SET_COMPLETED,
  REMOVE_COMPLETED,
  SET_LOCATION,
  REMOVE_LOCATION,
  SET_CITY,
  SET_URL,
  SET_QUIZ_COMPLETE,
  REMOVE_QUIZ_COMPLETE,
} from "./actions";
import { initialState } from "./initial_state";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPLETED:
      return {
        ...state,
        todosCompleted: {
          ...state.todosCompleted,
          [action.id]: action.time,
        },
      };

    case REMOVE_COMPLETED:
      const { [action.id]: val, ...otherIDs } = state.todosCompleted;
      return {
        ...state,
        todosCompleted: {
          ...otherIDs,
        },
      };

    case SET_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          zipcode: action.zipcode,
          climate: action.climate,
          state: action.state,
        },
      };

    case REMOVE_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          zipcode: action.zipcode,
          climate: action.climate,
          state: action.state,
        },
      };

    case SET_CITY:
      return {
        ...state,
        location: {
          ...state.location,
          city: action.city,
        },
      };

    case SET_URL:
      return {
        ...state,
        location: {
          ...state.location,
          url: action.url,
        },
      };

    case SET_QUIZ_COMPLETE:
      return {
        ...state,
        quizCompleted: {
          grade: action.grade,
          time: {
            season: action.season,
            year: action.year,
          },
        },
      };

    case REMOVE_QUIZ_COMPLETE:
      return {
        ...state,
        quizCompleted: {
          grade: action.grade,
          time: {
            season: action.season,
            year: action.year,
          },
        },
      };

    default:
      return state;
  }
}
