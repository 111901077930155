export const SET_COMPLETED = "SET_COMPLETED";
export function setCompleted(itemId, timestamp) {
  return {
    type: SET_COMPLETED,
    id: itemId || "",
    time: timestamp || "",
  };
}

export const REMOVE_COMPLETED = "REMOVE_COMPLETED";
export function removeCompleted(itemId) {
  return {
    type: REMOVE_COMPLETED,
    id: itemId || "",
  };
}

export const SET_LOCATION = "SET_LOCATION";
export function setLocation(zip, climateID, stateID) {
  return {
    type: SET_LOCATION,
    zipcode: zip || null,
    climate: climateID || null,
    state: stateID || null,
  };
}

export const REMOVE_LOCATION = "REMOVE_LOCATION";
export function removeLocation() {
  return {
    type: REMOVE_LOCATION,
    zipcode: null,
    climate: null,
    state: null,
    city: null,
  };
}

export const SET_CITY = "SET_CITY";
export function setCity(cityName) {
  return {
    type: SET_CITY,
    city: cityName || null,
  };
}

export const SET_URL = "SET_URL";
export function setURL(url) {
  return {
    type: SET_URL,
    city: url || null,
  };
}

export const SET_QUIZ_COMPLETE = "SET_QUIZ_COMPLETE";
export function setQuizComplete(grade, season, year) {
  return {
    type: SET_QUIZ_COMPLETE,
    grade: grade,
    season: season,
    year: year,
  };
}

export const REMOVE_QUIZ_COMPLETE = "REMOVE_QUIZ_COMPLETE";
export function removeQuizComplete() {
  return {
    type: REMOVE_QUIZ_COMPLETE,
    grade: null,
    season: null,
    year: null,
  };
}
